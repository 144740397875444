import React from 'react';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Heading, { StyledHeading } from '~components/Heading';
import { IconCard } from '~components/Card';
import ImageList, { imageListStyle } from '~components/ImageList';
import Text from '~components/Text';

import Access from '~components/Svg/Access';
import Bag from '~components/Svg/Bag';
import Check from '~components/Svg/Check';
import Directions from '~components/Svg/Directions';
import Globe from '~components/Svg/Globe';
import Pointer from '~components/Svg/Pointer';

import { VARIANT } from '../../enums';

const TruthPage: React.FC = () => (
  <Layout>
    <SEO title="Truth and Reconciliation " desc="" pathname="/asipirations/truth-reconciliation" />
    <Hero title="Meaningfully advance Truth and Reconciliation">
      <StaticImage src="../../images/wall.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="container mb-24">
      <div tw="max-w-5xl">
        <Text>
          The path towards Truth and Reconciliation is sharing and understanding the truth of
          Indigenous history and people, working towards decolonization and Indigenization, and
          building relationships in order to do good reconciliation work.
        </Text>
      </div>
    </div>
    <ImageList
      title="Strategic Direction"
      items={[
        'Commit to including Indigenous knowledge, culture and history in all areas of the college experience, both in and out of the classroom, including the integration of Indigenous Learning Outcomes into programs of studies throughout the college.',
        'In partnership with Indigenous communities, create a Centre of Indigenous Knowledge to support increased Truth and Reconciliation. This Centre will increase our leadership and support of learning, research, Indigenization, decolonization and reconciliation in education.',
        'Develop an Indigenous knowledge program for faculty and staff, including an on-boarding process for new employees, and develop a training model for both public and private sectors.',
        'Working alongside Indigenous communities, evaluate our effectiveness on meeting our commitments to the Colleges and Institutes Canada Indigenous Education Protocol and the Truth and Reconciliation Commission Calls to Action.',
        'Work with Indigenous communities to provide leadership for protection and access to clean drinking water.'
      ]}
    >
      <StaticImage
        src="../../images/hoop-dance.jpg"
        alt=""
        layout="fullWidth"
        style={imageListStyle}
      />
    </ImageList>
    <div tw="mb-24 bg-orange text-gray-800 py-12">
      <div tw="container">
        <div tw="justify-between space-x-6 items-center md:(flex)">
          <Directions tw="h-32 w-32 flex-shrink-0 md:(order-2 h-48 w-48)" />
          <div tw="md:(order-1)">
            <Heading level="h2" tw="mt-0">
              Leadership Outcome
            </Heading>
            <Text tw="text-2xl font-bold">
              Guided by the Indigenous Education Council and with the support of Indigenous
              communities, we will be trusted as a committed leader and true partner in advancing
              Truth and Reconciliation.
            </Text>
          </div>
        </div>
      </div>
    </div>
    <div tw="container">
      <div tw="max-w-5xl mb-12">
        <Heading level="h2" tw="text-rose">
          Building on Momentum
        </Heading>
        <Text tw="text-xl font-bold">
          Through ongoing engagement with our Indigenous partners, we are working to integrate
          Indigenous perspectives in all aspects of college practice and policy to achieve
          comprehensive cultural and structural change.
        </Text>
        {/* <Heading level="h3">1. Mohawk first</Heading>
        <Text>
          Mohawk has Canada’s first research centre dedicated to improving college student success.
          The College Student Success Innovation Centre focuses on interventions throughout the
          student lifecycle that are backed by research about what works to improve student
          retention and graduation.
        </Text>
        <Heading level="h3">2. Mohawk best</Heading>
        <Text>
          Mohawk’s Future Ready Partnerships with organizations and companies in the community
          provide experiential learning opportunities to connect students with real-world clients,
          for example, Broadcast Media students work directly with clients who come to the college
          through the McKeil School of Business, to produce promotional and social media videos.
        </Text>
        <Heading level="h3">3. Mohawk best</Heading>
        <Text>
          Many Mohawk faculty members are incorporating augmented or virtual reality (AVR) into
          their teaching to engage students in learning. Another important use of AVR is to prepare
          students for their placements, or to stand in for placements that aren’t available, from
          Support Service Workers to Construction Engineering Technician-Building Renovation to
          Electrical and Computer Engineering programs.
        </Text>
        <Heading level="h3">4. Mohawk only</Heading>
        <Text>
          Mohawk is the only college in Canada with sophisticated virtual maintenance trainers, the
          same used to train working mechanics for the Boeing 737. The 10 the virtual trainers are
          located at the college’s new aviation campus at Hamilton International Airport.
        </Text> */}
      </div>
      <StyledHeading>Aspirations</StyledHeading>
      <div tw="grid gap-10 my-12 md:(grid-cols-2) lg:(grid-cols-3)">
        <IconCard variant={VARIANT.RED} to="/aspirations/future-ready" Icon={<Check tw="w-12" />}>
          Enable Future Ready education, research, programs, services and experiences – for
          students, employees and our community
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/celebrate-every-person"
          Icon={<Access tw="w-12" />}
        >
          Be a place that honours, values and celebrates the whole of every person
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/education" Icon={<Pointer tw="w-12" />}>
          Ensure access to education and reduce barriers for all
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/workforce-development"
          Icon={<Bag tw="w-12" />}
        >
          Lead in workforce development
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/climate-change" Icon={<Globe tw="w-12" />}>
          Make a measurable impact on climate change
        </IconCard>
      </div>
    </div>
  </Layout>
);

export default TruthPage;
